<template>
  <v-row
    v-if="currentStep == 0"
    no-gutters
  >
    <v-col>
      <v-skeleton-loader
        :loading="loadingStepper || loadingStepperData"
        :style="{ height: '100%', width: '100%' }"
        type="card-heading, image@3"
      >
        <base-top-menu
          v-model="currentStep"
          class="top-menu"
          :menu="pageList"
          cancel-button-text="Закрыть"
          :action-button-text="isEdit ? 'Сохранить' : 'Создать'"
          @cancelbutton="clickedBack"
          @actionbutton="saveTemplate"
        >
          <v-row
            justify="center"
            class="row__master-stepper"
            style="z-index: 0"
            no-gutters
          >
            <v-col
              :cols="12"
              :sm="12"
              :md="11"
              :lg="10"
            >
              <v-tabs-items
                v-model="currentStep"
              >
                <v-tab-item
                  :value="0"
                >
                  <main-step
                    v-if="(isEdit || isCopy) ? !loadingStepperData : true"
                    ref="stepMain"
                    :stepper-data.sync="stepperData"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </base-top-menu>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { deepClone, trimLR } from '@/utils'

  export default {
    components: {
      MainStep: () => import('./steps/MainStep.vue'),
    },
    props: {
      sendingId: {
        type: [Number, String],
        default: NaN,
      },
      copyId: {
        type: [Number, String],
        default: NaN,
      },
    },
    provide () {
      return {
        isEdit: this.isEdit,
        isCopy: this.isCopy,
        rules: this.rules,
      }
    },
    data () {
      return {
        currentStep: 0,
        loadingStepper: false,
        stepperList: [
          { title: 'Рассылка' },
        ],
        pageList: [
          { id: '#main', name: 'Рассылка', route: { hash: '#main' } },
        ],
        stepperData: {
          name: '',
          channel_type: 'PUSH_DATABASE',
          fallback_notification_id: null,
          content: {},
        },
        loadingStepperData: true,
        rules: {
          name: [
            v => !!v || 'Введите название рассылки',
            v => trimLR(v).length <= 255 || 'Не должно превышать 255 символов',
            v => {
              const templates = this.isEdit ? this.templatesWithoutCurrent : this.templates
              return this.$_.where(templates, { name: v }).length === 0 || 'Такое имя рассылки уже существует'
            },
          ],
          channel_type: [
            v => !!v || 'Выберите тип рассылки',
          ],
          contentTitle: [
            v => {
              if (this.isChannelType('SMS')) return true
              return !!v || 'Обязательно для заполнения'
            },
            v => trimLR(this.strWithoutTemplate(v)).length <= 255 || 'Не должен превышать 255 символов',
          ],
          contentDescription: [
            v => {
              if (this.isChannelType('PUSH_DATABASE')) {
                return !!v || 'Обязательно для заполения'
              }
              return true
            },
            v => trimLR(this.strWithoutTemplate(v)).length <= 255 || 'Не должен превышать 255 символов',
          ],
          contentBody: [
            v => {
              if (this.isChannelType('EMAIL') || this.isChannelType('SMS') || this.isChannelType('EDA')) {
                return !!v || 'Обязательно для заполнения'
              }
              return true
            },
            v => {
              if (this.isChannelType('PUSH_DATABASE') ||
                this.isChannelType('EMAIL')) {
                return trimLR(v).length <= 100000 || 'Не должно превышать 100000 символов'
              } else if (this.isChannelType('SMS')) {
                return (new TextEncoder().encode(trimLR(this.strWithoutTemplate(v)))).length <= 1120 || 'Сообщение не должно превышать 8 смс'
              } else if (this.isChannelType('APPLE_WALLET')) {
                return this.strWithoutTemplate(v).trim().length <= 255 || 'Сообщение не должно превышать 255 символов'
              } else if (this.isChannelType('WHATS_APP')) {
                return this.strWithoutTemplate(v).trim().length <= 65536 || 'Сообщение не должно превышать 65536 символов'
              } else if (this.isChannelType('EDA')) {
                return this.strWithoutTemplate(v).trim().length <= 1024 || 'Сообщение не должно превышать 1024 символов'
              }
            },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        template: 'company/notifications/template',
        templates: 'company/notifications/templates',
      }),
      isEdit () {
        return Boolean(this.sendingId && !isNaN(this.sendingId))
      },
      isCopy () {
        return Boolean(this.copyId && !isNaN(this.copyId))
      },
      templatesWithoutCurrent () {
        if (this.isEdit) {
          return this.templates.filter(t => t.id !== this.stepperData.id)
        }
        return []
      },
      validData () {
        const data = this.stepperData
        const cbkValues = [data.name, data.channel_type,
                           data.content.title, data.content.description, data.content.body]
        let isValid = true

        Object.entries(this.rules).forEach(([key, arrRules], idx) => {
          arrRules.forEach(callback => {
            const check = callback(cbkValues[idx])
            if (
              (typeof check !== 'boolean' ||
                check !== true) && typeof cbkValues[idx] !== 'undefined'
            ) {
              isValid = false
            }
          })
        })

        return isValid
      },
    },
    async mounted () {
      try {
        this.loadingStepperData = true
        if (this.isEdit || this.isCopy) {
          await this.$store.dispatch('company/notifications/list', { program_id: this.programId })
          await this.setEditStepperData()
        } else {
          await this.$store.dispatch('company/notifications/list', { program_id: this.programId })
        }
      } finally {
        this.loadingStepperData = false
      }
    },
    methods: {
      ...mapActions({
        createTemplate: 'company/notifications/create',
        updateTemplate: 'company/notifications/update',
        whatsAppImageUpload: 'company/notifications/uploadImageWhatsAppImage'
      }),
      async saveTemplate () {
        if (!this.$refs.stepMain.$refs.form.validate() || !this.validData) {
          this.$notify({
            type: 'error',
            title: 'Рассылка',
            text: 'Ошибки валидации',
          })
          return
        }

        try {
          let id;

          this.loadingStepperData = true
          if (this.isEdit) {
            await this.updateTemplate(this.stepperData)
            id = this.stepperData.id;
          } else {
            const result = await this.createTemplate(this.stepperData)
            id = result.id
          }

          if (this.isChannelType('WHATS_APP') && this.stepperData.content.file instanceof File) {
                await this.whatsAppImageUpload({ id: id, image: this.stepperData.content.file})
          }

          this.$router.push({ name: 'Mailing' })
        } catch (err) {
          console.log(err)
        } finally {
          this.loadingStepperData = false
        }
      },
      async setEditStepperData () {
        const id = this.isEdit ? this.sendingId : this.copyId
        await this.$store.dispatch('company/notifications/read', { id })
        const template = deepClone(this.template).last_version
        if (this.isCopy) {
          template.name += ' (копия)'
        }
        this.stepperData = template
      },
      strWithoutTemplate (str) {
        return str ? str.replace(/{{\s*[\d\w\s\\.\\[\]]+\s*}}/g, '') : ''
      },
      clickedBack () {
        this.$router.back()
      },
      isChannelType (type) {
        return this.stepperData.channel_type === type
      },
    },
  }
</script>

<style lang="scss" scoped>
.row__master-stepper {
  & ::v-deep {
    .v-window {
      overflow: visible;
    }
    .v-btn {
      p {
        margin-left: 8px;
      }
    }
  }
}
.top-menu {
  & ::v-deep {
    .form-menu__item-block {
      justify-content: center;
      .form-menu__item-active  {
        border-bottom: 0!important;
      }
    }
  }
}
</style>
